<template>
  <div>
    <TosListVuetify pageTitle="Audits" serviceName="audit" entityName="audits" :headers="headers" :defaultSort="defaultSort">
      <template #customFilters="slotProps">
        <v-container fluid>
          <v-row dense>
            <v-col cols="2" >
              <v-text-field :label="tLabel('Unit')" v-model="slotProps.data.detailItem" dense/>
            </v-col>
            <v-col cols="2">
              <v-text-field :label="tLabel('Operazione')" v-model="slotProps.data.title" dense/>
            </v-col>
            <v-col cols="2">
              <v-text-field :label="tLabel('Oggetto')" v-model="slotProps.data.detail" dense/>
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('Request contiene')" v-model="slotProps.data.requestBodyLike" dense/>
            </v-col>
            <v-col cols="3">
              <v-text-field :label="tLabel('Response contiene')" v-model="slotProps.data.responseBodyLike" dense/>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2">
              <DateTimeAdapter :label="tLabel('Data da')" v-model="slotProps.data.createdAtGreatherThan" />
            </v-col>
            <v-col cols="2">
              <DateTimeAdapter :label="tLabel('Data a')" v-model="slotProps.data.createdAtLessThan" />
            </v-col>
            <v-col cols="2">
              <v-text-field :label="tLabel('Path')" v-model="slotProps.data.pathLike" />
            </v-col>
            <v-col cols="1">
              <v-text-field :label="tLabel('Method')" v-model="slotProps.data.method" />
            </v-col>
            <v-col cols="1">
              <v-text-field :label="tLabel('Stato')" v-model="slotProps.data.status" />
            </v-col>
            <v-col cols="1">
              <v-text-field :label="tLabel('User')" v-model="slotProps.data.userName" />
            </v-col>
            <v-col cols="1">
              <v-text-field :label="tLabel('Source')" v-model="slotProps.data.source" />
            </v-col>
            <v-col cols="1">
              <v-checkbox :label="tLabel('Exlude Internal')" :title="tLabel('Exclude internal')" v-model="slotProps.data.internalIsNull" />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.actions="item">
        <v-btn icon title="Details" @click="showDetails(item.data)">
          <v-icon color="primary" medium>mdi-import</v-icon>
        </v-btn>
      </template>
    </TosListVuetify>

    <v-dialog v-model="showDetailsVisible" max-width="600" header="Dettagli">
      <v-card>
        <v-card-title>
          {{ item.title }}
        </v-card-title>
        <v-card-text>
          <span>{{item.createdAt | formatDateTimeSecsFromDate}}</span>
          <v-textarea label="request" v-model="item.requestBody" @click="showJson(item.requestBody)" title="click per formattare"> </v-textarea>
          <v-textarea label="response" v-model="item.responseBody" @click="showJson(item.responseBody)" title="click per formattare"> </v-textarea>

          <v-data-table v-if="details.length > 0" dense :headers="detailsHeaders" :items="details" item-key="id" :search="search" class="elevation-1">
            <template v-slot:top>
              <v-text-field v-model="search" label="Search" class="mx-4"></v-text-field>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showDetailsVisible = false" small>Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showJsonVisible"  header="JSON"  scrollable>
      <v-card>
        <v-card-text>
          <p class="overflow-auto">
            <pre>{{jsonValue}}</pre>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="copyDisabled" @click="copyToClipboard" small>Copia</v-btn>
          <v-btn @click="showJsonVisible = false" small>Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import TosListVuetify from "@/components/anagrafiche/TosListVuetify";
import FormatsMixins from "@/mixins/FormatsMixins";
import DateTimeAdapter from "@/components/anagrafiche/DateTimeAdapter";
import { throws } from "assert";

export default {
  name: "Audit",
  data() {
    return {
      showDetailsVisible: false,
      item: {},
      details: [],
      search: null,
      jsonValue: null,
      showJsonVisible: false,
      copyDisabled: false,
      defaultSort: { field: "createdAt", desc: false },
      headers: [
        { text: `${this.tLabel("Data")}`, value: "createdAt", formatValue: "formatDateTimeSecsFromDate" },
        { text: `${this.tLabel("Operazione")}`, value: "title" },
        { text: `${this.tLabel("Oggetto")}`, value: "detail" },
        { text: `${this.tLabel("User")}`, value: "userName" },
        { text: `${this.tLabel("Path")}`, value: "path" },
        { text: `${this.tLabel("Method")}`, value: "method" },
        { text: `${this.tLabel("Status")}`, value: "status" },
        { text: `${this.tLabel("Source")}`, value: "source" },
        { text: `${this.tLabel("Internal")}`, value: "internal" },
      ],
      detailsHeaders: [
        { text: `${this.tLabel("category")}`, value: "category" },
        { text: `${this.tLabel("Value")}`, value: "value" },
      ],
    };
  },
  mounted() {
    this.loadCombo();
  },

  methods: {
    async prepareItem(data) {},
    async loadCombo() {},
    async showDetails(item) {
      this.item = item;
      const audit = await this.$api.get(this.$apiConfiguration.BASE_PATH_AUDIT + "audits/" + item.id);
      this.details = audit.details ? audit.details : [];
      this.showDetailsVisible = true;
    },
    showJson(value){
      this.jsonValue=this.formatJsonValue(value);
      this.showJsonVisible=true;
      this.copyDisabled=false;
    },
    formatJsonValue(value) {
        try{
        const jsonValue= JSON.parse(value);
        return JSON.stringify(jsonValue, (k, v) => v ?? undefined, 2);
        } catch(e){ return value}
    },
    copyToClipboard(){
      navigator.clipboard.writeText(this.jsonValue);
      this.copyDisabled=true;
    },
  },
  components: { TosListVuetify, DateTimeAdapter },
  mixins: [FormatsMixins],
};
</script>
